import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the CreatorRequest Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
    static targets = ["select"]
    /*
     * Regular Stimulus lifecycle methods
     * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
     *
     * If you intend to use this controller as a regular stimulus controller as well,
     * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
     *
     * Important:
     * By default, StimulusReflex overrides the -connect- method so make sure you
     * call super if you intend to do anything else when this controller connects.
     */

    take_new(e){

        var url  = $("#header").data("url")



        window.location.href = url

    }

    connect () {
        super.connect()
        // add your code here, if applicable
        var siht  = this;

        $("#select").on('select2:select', function (e) {
            var data = { }

            data["value"] = $(this).val()
            var url  = $("#header").data("url")


            url = url +"?cr=" +data["value"]
            window.location.href = url
//            alert(url);
  //          siht.stimulate("CreatorRequest#select_creator",data)

        })

    }

    save_creator(e){
        e.preventDefault();
        $("#saving-creator").show();
        this.stimulate("CreatorRequest#save_creator").then((e) => {
            setTimeout(() => {
                var id  = $("#header").data("creator-id")
                var url  = $("#header").data("url")
                url = url +"?cr=" +id
                //window.location.href = url


                var creator_id = id;
                var selects = ["vocalists", "composers", "record_labels", "sound_studios", "music_producers"]
                window.selectLoader();

                selects.forEach((d) => {
                    this.convert(d);
                })
                $("#saving-creator").hide();
            }, 1000)
        })
    }

    convert(d){

        var creator_id  = $("#header").data("creator-id")
        var data = $("#header").data(d)

        data.split(",").forEach((item) => {
            var id = item.split(":")[0]
            var name = item.split(":")[1]

            if(id != 0 && name != "" && name != null && name != undefined){

                var newOption = new Option(name,parseInt(id), true, true);
                if(d == "record_labels"){
                    $('.record_levels-'+creator_id).append(newOption).trigger('change');
                }else if(d == "sound_studios"){
                    $('.second_studios-'+creator_id).append(newOption).trigger('change');
                }else if(d == "music_producers"){
                    $('.music_producers-'+creator_id).append(newOption).trigger('change');
                }else{
                    $('.'+d+'-'+creator_id).append(newOption).trigger('change');
                }
            }


        })



    }

    save_creator_request(e){
        e.preventDefault();

        $("#saving").show();
        this.stimulate("CreatorRequest#save_creator_request")

    }

    save_to_creator(e){
        e.preventDefault();
        $("#saving").show();
        this.stimulate("CreatorRequest#save_to_creator")
    }

    disconnect() {
        $("#select").off('select2:select');

    }


    /* Reflex specific lifecycle methods.
     *
     * For every method defined in your Reflex class, a matching set of lifecycle methods become available
     * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
     * need them.
     *
     * Important:
     * Make sure to add data-controller="creator-request" to your markup alongside
     * data-reflex="CreatorRequest#dance" for the lifecycle methods to fire properly.
     *
     * Example:
     *
     *   <a href="#" data-reflex="click->CreatorRequest#dance" data-controller="creator-request">Dance!</a>
     *
     * Arguments:
     *
     *   element - the element that triggered the reflex
     *             may be different than the Stimulus controller's this.element
     *
     *   reflex - the name of the reflex e.g. "CreatorRequest#dance"
     *
     *   error/noop - the error message (for reflexError), otherwise null
     *
     *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
     */

    // Assuming you create a "CreatorRequest#dance" action in your Reflex class
    // you'll be able to use the following lifecycle methods:

    // beforeDance(element, reflex, noop, reflexId) {
    //  element.innerText = 'Putting dance shoes on...'
    // }

    // danceSuccess(element, reflex, noop, reflexId) {
    //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
    // }

    // danceError(element, reflex, error, reflexId) {
    //   console.error('danceError', error);
    //   element.innerText = "\nCouldn\'t dance!"
    // }

    // afterDance(element, reflex, noop, reflexId) {
    //   element.innerText = '\nWhatever that was, it\'s over now.'
    // }

    // finalizeDance(element, reflex, noop, reflexId) {
    //   element.innerText = '\nNow, the cleanup can begin!'
    // }
}
