import consumer from './consumer'

console.log("importing consumer")
consumer.subscriptions.create('ReleaseGridUpdateChannel', {

    received: function(data){
	var data = JSON.parse(data);
	console.log(data);
	var rowNode = window.gridOptions.api.getRowNode(data.id);
	console.log(data.id);


	rowNode.setData(data);
	window.gridOptions.api.flashCells({ rowNodes: [rowNode]});

    }
})
